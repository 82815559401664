/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';
import SEO from '../components/seo';

interface Props {
  pageContext: {
    html: string;
    crumbs: {
      slug: string;
      display: string;
    }[];
    name: string;
  };
}

export class Template extends React.Component<Props> {
  componentDidMount() {
    const toggle = document.getElementById('toggle');
    if (!toggle) {
      return;
    }
    const details = document.getElementsByTagName('details');

    toggle.addEventListener('click', this.handleToggle(toggle, details), false);

    document.addEventListener('keydown', this.handleKeyDown(toggle, details), false);
  }

  componentWillUnmount() {
    const toggle = document.getElementById('toggle');
    if (!toggle) {
      return;
    }
    const details = document.getElementsByTagName('details');
    document.removeEventListener('keydown', this.handleKeyDown(toggle, details), false);
  }

  handleToggle = (toggle, details) => () => {
    toggle.classList.toggle('expanded');
    toggle.classList.toggle('collapsed');

    if (toggle.classList.contains('expanded')) {
      for (let i = 0; i < details.length; i += 1) {
        details[i].setAttribute('open', '');
      }
    } else {
      for (let i = 0; i < details.length; i += 1) {
        details[i].removeAttribute('open');
      }
    }
  };

  handleKeyDown = (toggle, details) => (event) => {
    const { keyCode } = event;
    const letterE = 69;
    if (keyCode === letterE) {
      this.handleToggle(toggle, details)();
    }

  }

  render() {
    const { pageContext } = this.props;
    const title = pageContext.name;

    return (
      <div>
        <Layout>
        <SEO title={title} />
          <Subheader crumbs={pageContext.crumbs} />
          <div dangerouslySetInnerHTML={{ __html: pageContext.html }} />
        </Layout>
      </div>
    );
  }
}

export default Template;
